






















































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'sensitiveManage' })
export default class sensitiveManage extends VueBase {
  private pattern_type = []
  private strategyData = []
  private pattern_type_map = {}
  private sensitiveForm: any = {}
  private validatioData = ''
  private rules = {
    strategy_id: [
      {
        required: true,
        message: this.$t('views.sensitiveManage.nameR') as string,
        trigger: 'blur',
      },
    ],
    pattern_type_id: [
      {
        required: true,
        message: this.$t('views.sensitiveManage.detailR') as string,
        trigger: 'blur',
      },
    ],
    pattern: [
      {
        required: true,
        message: this.$t('views.sensitiveManage.fixR') as string,
        trigger: 'blur',
      },
    ],
  }

  private async regexValidation() {
    const form: any = this.$refs.ruleForm
    form.validate(async (valid: boolean) => {
      if (valid) {
        this.loadingStart()
        const res = await this.services.setting.regex_validation({
          url: this.pattern_type_map[this.sensitiveForm.pattern_type_id].url,
          pattern: this.sensitiveForm.pattern,
          test_data: this.sensitiveForm.test_data,
        })
        this.loadingDone()
        if (res.status === 201) {
          if (res.data.data === '') {
            this.$message.warning(
              this.$t('views.sensitiveManage.noValidatio') as string
            )
          }
          this.validatioData = res.data.data
        } else {
          this.$message.error(res.msg)
        }
      }
    })
  }
  private async getStrategyData() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyList(
      true,
      {}
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.strategyData = data
  }

  async sensitiveAdd() {
    // 新增确认
    this.loadingStart()
    this.sensitiveForm.status = 0
    const res = await this.services.setting.create_sensitive_info_rule(
      this.sensitiveForm
    )
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success('匹配规则创建成功')
      this.goBack()
    } else {
      this.$message.error('匹配规则创建失败，请重试')
    }
  }
  async sensitiveEdit() {
    // 编辑确认
    this.loadingStart()
    const res = await this.services.setting.update_sensitive_info_rule(
      this.sensitiveForm
    )
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success('匹配规则编辑成功')
      this.goBack()
    } else {
      this.$message.error('匹配规则编辑失败，请重试')
    }
  }
  async sensitiveDialogEdit() {
    // 编辑获取数据
    const res = await this.services.setting.get_sensitive_info_rule_one({
      id: this.$route.query.id,
    })
    if (res.status === 201) {
      this.sensitiveForm = res.data
    } else {
      this.$message.error(res.msg)
    }
  }
  sensitiveDialogEnter() {
    const form: any = this.$refs.ruleForm
    form.validate((valid: boolean) => {
      if (valid) {
        if (this.$route.query.id) {
          this.sensitiveEdit()
        } else {
          this.sensitiveAdd()
        }
      }
    })
  }
  async goBack() {
    this.$router.push({ name: 'settingSensitive' })
  }
  async created() {
    this.loadingStart()
    await this.getPatternType()
    await this.getStrategyData()
    if (this.$route.query.id) {
      await this.sensitiveDialogEdit()
    }
    this.loadingDone()
  }

  private async getPatternType() {
    const res = await this.services.setting.pattern_type()
    if (res.status === 201) {
      this.pattern_type = res.data
      this.pattern_type.forEach((item: any) => {
        this.$set(this.pattern_type_map, item.id, {
          name: item.name,
          url: item.url,
        })
      })
    } else {
      this.$message.error(res.msg)
    }
  }
}
